<template>
  <div>
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>特別エントリー管理</span>
      </div>
      <div v-show="type == 1">
        <div class="tool-bar">
          <el-input placeholder="タイトル名" v-model="searchParams.entryName" class="input-col"></el-input>
          <el-date-picker type="month" placeholder="エントリー月/From" v-model="searchParams.entryDateStart" value-format="yyyy-MM-dd" format="yyyy/MM" clearable class="date-picker-col">
          </el-date-picker>
          <el-date-picker type="month" placeholder="エントリー月/To" v-model="searchParams.entryDateEnd" value-format="yyyy-MM-dd" format="yyyy/MM" clearable class="date-picker-col">
          </el-date-picker>
          <el-button round icon="el-icon-search" class="search-btn-col" @click="searchList">検索</el-button>
          <el-button type="info" round class="reset-btn-col" @click="reset()">リセット</el-button>
        </div>

        <div class="table-box">
          <div class="table-content">
            <div class="table-c">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column min-width="40%" label="エントリー日">
                  <template slot-scope="scope">
                    <span>{{scope.row.entryDate | timeFormat4}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="entryName" min-width="70%" label="タイトル名"></el-table-column>
                <el-table-column prop="userName" min-width="35%" label="氏名"></el-table-column>
                <el-table-column prop="genderName" min-width="20%" label="性別"></el-table-column>
                <el-table-column prop="mail" min-width="45%" label="メールアドレス"></el-table-column>
                <el-table-column prop="schoolName" min-width="35%" label="学校名"></el-table-column>
                <el-table-column prop="sectionCategoryName" min-width="30%" label="学部"></el-table-column>
                <el-table-column prop="facultyDepartment" min-width="30%" label="学科"></el-table-column>
                <el-table-column prop="graduationYearName" min-width="30%" label="卒業年度"></el-table-column>
                <el-table-column min-width="42%" label="操作">
                  <template slot-scope="scope">
                    <template>
                      <div @click="goDetail(scope.row)" class="detail-btn-col">ユーザー詳細</div>
                      <!-- <el-button type="success" plain round  class="detail-btn-col">ユーザー詳細</el-button> -->
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="page-col">
          <el-pagination background :total="total" 
              layout="prev, pager, next"
              @current-change="handleCurrentChange"  
              :current-page.sync="searchParams.pageIndex"
              :page-size="searchParams.pageSize" >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js"
export default {
  name: "specialEntry",
  data() {
    return {
      searchParams: {
        entryName: "",
        entryType: 2,
        entryDateStart:"",
        entryDateEnd:"",
        pageSize: 20,
        pageIndex: 1,
      },
      tableData: [],
      total:0,
      type: 1,
      userInfo: {},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    this.searchList();
  },
  methods: {
    reset() {
      this.searchParams = {
        entryName: "",
        entryType: 2,
        entryDateStart:"",
        entryDateEnd:"",
        pageSize: 20,
        pageIndex: 1,
      };
      this.searchList();
    },
    goDetail(item){
      this.$router.push({
        path: '/index/specialEntryDetail',
        query: {
          id: item.id,
          params: encodeURI(JSON.stringify(this.searchParams))
        }
      })
    },
    handleCurrentChange(val) {
        this.searchParams = {...this.searchParams, pageIndex: val};
        this.searchList();
    },
    async searchList() {
      this.searchParams.compId = this.userInfo.compId;
      let res = await this.$axios({url: `/entry/specialEntryUserList`, data: this.searchParams, method: "post"});
      if(res.code == '000') {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  }
}
</script>

<style scoped src="../../style/specialEntry.css">
</style>